/* eslint-disable func-names,no-param-reassign,no-shadow */
import React, { useEffect, useState } from 'react'
import { func, bool } from 'prop-types'
import Helmet from 'react-helmet'
import { getAffirmBody } from '@helpers/checkout/payment-section/affirm'
import AffirmLogo from '@assets/images/co-affirm2.svg'
import PaymentTypeButton from '../payment-type-button'

const AffirmButtonWrapper = ({ setOrderInfo, isApplied }) => {
  const [affirmLoaded, setAffirmLoaded] = useState(false)

  useEffect(() => {
    setAffirmLoaded(true)
  }, [])

  const affirmFunc = (l, g, m, e, a, f, b) => {
    const c = l[m] || {}
    const h = document.createElement(f)
    const n = document.getElementsByTagName(f)[0]
    const k = function(a, b, c) {
      return function() {
        // eslint-disable-next-line prefer-rest-params
        a[b]._.push([c, arguments])
      }
    }
    c[e] = k(c, e, 'set')
    const d = c[e]
    c[a] = {}
    c[a]._ = []
    d._ = []
    c[a][b] = k(c, a, b)
    a = 0
    for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++)
      d[b[a]] = k(c, e, b[a])
    a = 0
    for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function() {}
    h.async = !0
    h.src = g[f]
    if (n) n.parentNode.insertBefore(h, n)
    delete g[f]
    d(g)
    l[m] = c
  }

  const affirmClick = () => {
    if (setOrderInfo instanceof Function) {
      setOrderInfo()
    }
    /* eslint-disable no-undef */
    affirm.checkout(getAffirmBody())
    affirm.checkout.open()
  }

  const affirmConfig = {
    public_api_key: process.env.GATSBY_AFFIRM_API_KEY,
    script: `${process.env.GATSBY_AFFIRM_URL}/js/v2/affirm.js`,
  }

  if (!affirmLoaded) {
    return (
      <Helmet>
        <script>{affirmFunc(window, affirmConfig, 'affirm', 'checkout', 'ui', 'script', 'ready')}</script>
      </Helmet>
    )
  }

  return (
    <PaymentTypeButton
      isApplied={isApplied}
      ariaId="payment-option-affirm"
      gtmLabel="Affirm"
      handleClick={affirmClick}
      label="Pay Over Time, Up to 36 Months"
      startIcon={<img src={AffirmLogo} width="80px" style={{ margin: '0 auto' }} alt="Pay over time" />}
    />
  )
}

AffirmButtonWrapper.propTypes = {
  setOrderInfo: func,
  isApplied: bool,
}

export default AffirmButtonWrapper
