import React from 'react'
import { useDispatch } from 'react-redux'
import { bool, func, object, string } from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { format } from 'date-fns'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { updateDelivery } from '@services/checkout'
import { setOrder, setDeliveryMode } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { getDeliverySpecificBody, getSplitShipmentData } from '@helpers/checkout/delivery-section'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import CloseIcon from '@mui/icons-material/Close'
import PriceAtom from '../../../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

import { PICK_UP, DOORWAY_DELIVERY } from '../constants'
import SplitExpressItems from './split-express-item'

const StyledDialog = styled(Dialog)({
  padding: '24px',
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '400px',
      margin: { xs: 0 },
    },
    '& .MuiDialogContent-root': {
      padding: { xs: '18px' },
    },
  },
})

const ExpressTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: 500,
  display: 'flex',
  gap: '10px',
  color: '#07263B',
})

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  padding: '8px',
  top: 0,
  right: 0,
})

const SelectExpress = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
}))

const getSplitExpressDate = order =>
  order?.splitCalendar?.find(shipment => !_isEmpty(shipment.deliveryCalendar.expressDeliveryDate))?.deliveryCalendar
    ?.expressDeliveryDate ?? ''

const ExpressModal = ({ open, onClose, deliveryMode, isSplitDelivery, order, cart, earliestDeliveryDates }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()

  const splitExpressDate = order?.splitCalendar ? getSplitExpressDate(order) : ''

  const expressDate =
    isSplitDelivery && splitExpressDate
      ? format(new Date(splitExpressDate.replace(/-/g, '/')), 'EEEE, LLLL do')
      : earliestDeliveryDates[deliveryMode]

  const shipmentsData = isSplitDelivery ? getSplitShipmentData(cart.cartItems, order) : []
  const splitExpressItems = isSplitDelivery
    ? shipmentsData.find(shipment => shipment.expressDelivery !== undefined).items
    : []

  const updateExpressDelivery = async event => {
    const newOrder = {
      ...order,
      deliveryDate: isSplitDelivery ? order.deliveryDate : order.expressDeliveryDate,
      splitDeliveryDates:
        isSplitDelivery &&
        order.splitDeliveryDates.map(date =>
          Object.hasOwn(date, 'expressDelivery')
            ? { ...date, deliveryDate: splitExpressDate, expressDelivery: true }
            : date,
        ),
      doorwayDelivery: deliveryMode === DOORWAY_DELIVERY,
      isPickup: deliveryMode === PICK_UP,
    }
    await updateDelivery(getDeliverySpecificBody(newOrder, deliveryMode))
      .then(data => {
        dispatch(setOrder(data))
        dispatch(setDeliveryMode(deliveryMode))
        updateDeliveryAnalytics(data)
      })
      .catch(() => {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            orderId: order.orderId,
            section: 'Checkout: Delivery Section',
          },
          captureMessage: {
            message: sentryMessages.updateDeliveryFailure,
            level: levels.error,
          },
        })
      })

    onClose(event)
  }

  return (
    <StyledDialog
      PaperProps={{
        sx: {
          boxShadow: 'none',
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth={isMobile}
    >
      <StyledIconButton onClick={onClose}>
        <CloseIcon color="#07263B" />
      </StyledIconButton>
      <DialogContent>
        <ExpressTitle>
          {' '}
          <ScheduleSendIcon />
          Express Delivery Available
        </ExpressTitle>
        <Typography
          sx={{
            padding: '16px 0',
          }}
          fontSize="16px"
        >
          {`Get your delivery even faster. Receive your item(s) `}
          <Box component="span" fontWeight="bold">
            {expressDate}
          </Box>
          {` for an additional charge of `}
          <Box component="span" fontWeight="bold">
            <PriceAtom price={order.premiumExpressFee} />
          </Box>
          .
        </Typography>
        {isSplitDelivery && <SplitExpressItems items={splitExpressItems} />}
        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} mt={2} spacing={2} alignItems="center">
          <Button variant="text" onClick={onClose} fullWidth>
            No Thanks
          </Button>
          <SelectExpress variant="contained" color="primary" onClick={e => updateExpressDelivery(e)} fullWidth>
            Select Express
          </SelectExpress>
        </Stack>
      </DialogContent>
    </StyledDialog>
  )
}

ExpressModal.propTypes = {
  open: bool,
  onClose: func,
  order: object,
  deliveryMode: string,
  isSplitDelivery: bool,
  cart: object,
  earliestDeliveryDates: object,
}

export default ExpressModal
