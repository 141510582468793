import React, { useCallback, useState, useRef, useEffect } from 'react'
import { object, array, bool, objectOf } from 'prop-types'
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material'
import ReactToolTip from 'react-tooltip'
import { useDispatch } from 'react-redux'

import { setOrderInfo, getKlarnaAvailability, CHECKOUT_STEPS } from '@helpers/checkout/global'
import { applePayEnabled, applePaySupported } from '@helpers/checkout/payment-section/apple-pay'
import { getAppliedPayments, removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'
import { checkoutStepAnalytics } from '@helpers/google-tag-manager'
import { sentryLogger, levels, setExtra, cleanOrderObject, sentryMessages } from '@helpers/sentry-logger'

import ErrorMessage from '@shared/error-message'
import { updatePayment } from '@services/checkout'
import { store } from '@redux/store'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import { usePrevious } from '@hooks/usePrevious'

import Affirm from './affirm'
import ApplePayPaymentOption from './apple-pay-payment-option'
import PaymentCondensed from './payment-condensed'
import PaypalPaymentOption from './paypal-payment-option'
import PaymentTypeAcima from './payment-type-acima'
import PaymentTypeBitpay from './payment-type-bitpay'
import PaymentTypeConcora from './payment-type-concora'
import PaymentTypeCreditCard from './payment-type-credit-card'
import PaymentTypeGiftCard from './payment-type-gift-card'
import PaymentTypeKlarna from './payment-type-klarna'
import PaymentTypeSynchrony from './payment-type-synchrony'
import PaymentTypeWellsFargo from './payment-type-wells-fargo'
import FinanceMessage from './finance-message'
import AppliedPaymentCard from './applied-payment-card'
import RemovePaymentModal from './remove-payment-modal'
import { PaymentForm, PaymentWrapper, AppliedPaymentsContainer, AppliedPaymentsHeader, ShowAllButton } from './styles'

export const PAYMENT_TYPES = {
  ACIMA: 'ACIMA',
  CYBERV3: 'CYBERV3',
  CYBERV4: 'CYBERV4',
  GEN: 'GEN',
  VISA: 'VISA',
  AFF: 'AFF',
  GIFT: 'GIFT',
  DBUY: 'DBUY',
  PALV2: 'PALV2',
  APP: 'APP',
  FIN: 'FIN',
  BPAY: 'BIT',
  BIT: 'BIT',
  KLN: 'KLN',
  WFG: 'WFG',
}

export const PAYMENT_LABELS = {
  ACIMA: 'Acima',
  CYBERV3: 'Credit',
  CYBERV4: 'Credit',
  GEN: 'Concora Credit',
  VISA: 'Visa Checkout',
  AFF: 'Affirm',
  GIFT: 'Gift',
  DBUY: 'Rooms To Go',
  PALV2: 'PayPal',
  APP: 'Apple Pay',
  GIFTCD: 'Gift Card',
  BPAY: 'bitpay',
  BIT: 'bitpay',
  KLN: 'Klarna',
  WFG: 'Wells Fargo',
}

export const PAYMENT_CHECKOUT_LABELS = {
  ACIMA: 'Acima Leasing',
  CYBERV3: 'Credit',
  CYBERV4: 'Credit',
  GEN: 'Concora Credit X-',
  VISA: 'Visa Checkout',
  AFF: 'Affirm Finance',
  GIFT: 'Gift Card X-',
  DBUY: 'RTG Credit X-',
  PALV2: 'PayPal',
  APP: 'Apple Pay',
  GIFTCD: 'Gift Card',
  BIT: 'BitPay',
  BPAY: 'BitPay',
  KLN: 'Klarna',
}

export const PAYMENT_LOGOS = {
  ACIMA: 'acima',
  AFF: 'affirm',
  GEN: 'genesis',
  GIFT: 'gift',
  DBUY: 'rtg',
  PALV2: 'paypal',
  BIT: 'bitpay',
  BPAY: 'bitpay',
  KLN: 'klarna',
}

export const PAYMENT_CC_LABELS = {
  amex: 'American Express',
  discover: 'Discover',
  maestro: 'Maestro',
  mastercard: 'MasterCard',
  visa: 'Visa',
}

const INITIAL_MODAL_STATE = {
  acima: false,
  bitpay: false,
  credit: false,
  finance: false,
  genesis: false,
  giftCard: false,
  klarna: false,
  wells: false,
}

const FIELD = 'selectedPaymentType'
const GIFT_CARD_SKU = '83333333'

const removePayment = async (order, setLoading, paymentType, callback, multiTenderIndex) => {
  if (!order || !order.paymentInfo) {
    return
  }

  setLoading(true)
  let paymentInfo

  if (paymentType === 'CYBERV4') {
    paymentInfo = order.paymentInfo
      .map(payment => {
        if (payment?.paymentType === 'CYBERV4') {
          let newPaymentProperties = payment.paymentProperties.filter(
            ({ card }) => card.appliedIndex !== multiTenderIndex,
          )

          if (newPaymentProperties.length === 0) return null

          // need to reassign the appliedIndex values for each remaining CC payments:
          newPaymentProperties = newPaymentProperties.map((p, i) => ({
            ...p,
            card: { ...p.card, appliedIndex: i },
          }))

          return { paymentType, paymentProperties: newPaymentProperties }
        }
        return payment
      })
      .filter(x => x) // to filter out any null values
  } else {
    paymentInfo = order.paymentInfo.filter(payment => payment?.paymentType !== paymentType)
  }

  const data = await updatePayment({
    orderId: order?.orderId,
    paymentInfo,
  })
  try {
    store.dispatch(setOrder(data))
    setLoading(false)
    callback()
  } catch (e) {
    setLoading(false)
    sentryLogger({
      configureScope: {
        type: setExtra,
        level: levels.error,
        orderId: order.orderId,
        paymentType: paymentInfo[0].paymentType,
        order: cleanOrderObject(order),
      },
      captureMessage: {
        type: 'text',
        message: sentryMessages.paymentUpdateFailure,
        level: levels.error,
      },
    })
  }
  if (paymentType === PAYMENT_TYPES.KLN) {
    removeKlarnaSession()
  }
  // TODO - why is this repeated here? Is it even needed?
  if (paymentType === PAYMENT_TYPES.KLN) {
    removeKlarnaSession()
  }
}

const PaymentSection = ({ cart, checkoutStepsCompleted, invalidFields, isPaymentStep, order }) => {
  const [paymentInfo, setPaymentInfo] = useState([])
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE)
  const [showAll, setShowAll] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const cardInfoRef = useRef(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const dispatch = useDispatch()
  const previousOrder = usePrevious(order, true)

  useEffect(() => {
    setPaymentInfo(order.paymentInfo)
  }, [order])

  const confirmPaymentRemoval = payment => {
    cardInfoRef.current = { ...payment }
    setShowRemoveConfirmation(true)
  }

  useEffect(() => {
    if (isPaymentStep && previousOrder?.amountDue !== order?.amountDue && order?.amountDue <= 0) {
      checkoutStepAnalytics('review')
      dispatch(setCheckoutStep(CHECKOUT_STEPS.review))
    }
  }, [isPaymentStep, previousOrder, order, dispatch])

  const openModal = useCallback(type => {
    setModalState(currentModalState => ({ ...currentModalState, [type]: true }))

    switch (type) {
      case 'credit':
        return setOrderInfo(PAYMENT_LABELS.CYBERV3, FIELD, true)
      case 'finance':
      case 'genesis':
        return setOrderInfo(PAYMENT_LABELS.DBUY, FIELD, false)
      case 'klarna':
        return setOrderInfo(PAYMENT_LABELS.KLN, FIELD, true)
      case 'giftCard': {
        return setOrderInfo(PAYMENT_LABELS.GIFTCD, FIELD, true)
      }
      case 'wells': {
        return setOrderInfo(PAYMENT_LABELS.WFG, FIELD, true)
      }
      default:
        return null
    }
  }, [])

  const closeModals = useCallback(() => setModalState(INITIAL_MODAL_STATE), [])

  const isApplePayAvailable = !!(applePaySupported() && applePayEnabled())
  const isKlarnaAvailable = getKlarnaAvailability(order, cart)
  const giftCardInCart = (cart?.cartItems?.map(item => item.sku) ?? []).includes(GIFT_CARD_SKU)

  const hasNoAppliedPayments = !!(paymentInfo && !paymentInfo.length)
  const appliedPaymentTypes = paymentInfo.map(p => p.paymentType)
  const hasAffirmApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.AFF)
  const hasApplePayApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.APP)
  const hasGenesisApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.GEN)
  const hasGiftCardApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.GIFT)
  const hasMultiTenderApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.CYBERV4)
  const hasSynchronyApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.DBUY)
  const hasWellsFargoApplied = appliedPaymentTypes.includes(PAYMENT_TYPES.WFG)
  const hasFinancingApplied = hasAffirmApplied || hasGenesisApplied || hasSynchronyApplied || hasWellsFargoApplied

  // Acima lease payments are not currently allowed in MN, NJ, WI, or WY, so do not show Acima for those shipping addresses
  const acimaBlockedStates = ['MN', 'NJ', 'WI', 'WY']
  const isAcimaBlocked = acimaBlockedStates.includes(order?.shippingAddress?.state?.toUpperCase() ?? '')

  const showTypes = {
    acima: hasNoAppliedPayments && !isAcimaBlocked && !giftCardInCart,
    affirm: !hasFinancingApplied && !giftCardInCart && !hasMultiTenderApplied,
    applePay: isApplePayAvailable && hasNoAppliedPayments, // ApplePay cannot be combined with any other payment type
    bitpay: hasNoAppliedPayments,
    concora: !hasFinancingApplied && !giftCardInCart && !hasMultiTenderApplied,
    giftCard: !giftCardInCart && (hasNoAppliedPayments || appliedPaymentTypes.every(p => p === PAYMENT_TYPES.GIFT)),
    klarna: isKlarnaAvailable && !hasFinancingApplied && !hasMultiTenderApplied && !giftCardInCart,
    paypal: !hasMultiTenderApplied,
    synchrony: order?.financing && !hasFinancingApplied && !hasMultiTenderApplied && !giftCardInCart,
    wells: order?.altFinancing && !hasFinancingApplied && !hasMultiTenderApplied && !giftCardInCart,
  }

  const doNotCollapse =
    giftCardInCart || (appliedPaymentTypes.length > 0 && !appliedPaymentTypes.every(p => p === PAYMENT_TYPES.GIFT))

  const appliedPayments = getAppliedPayments(paymentInfo)

  return (
    <>
      {/* {!isPaymentStep && order?.amountDue <= 0 && checkoutStepsCompleted.payment && (
        <PaymentCondensed order={{ ...order, paymentInfo: [{ paymentType: 'ACIMA', authorizedAmount: 1500.44 }] }} />
      )} */}
      {!isPaymentStep && order?.amountDue <= 0 && checkoutStepsCompleted.payment && <PaymentCondensed order={order} />}

      {isPaymentStep && (
        <PaymentWrapper>
          {(order?.amountDue > 0 || paymentInfo.length === 0) && (
            <PaymentForm>
              <p className="required-label">
                {hasNoAppliedPayments ? 'Choose Your Payment Method' : 'Choose An Additional Payment Method'}
              </p>

              {invalidFields.length > 0 && (
                <ErrorMessage
                  customMessage={{
                    id: 'payment-section',
                    message: 'Cannot continue until a payment has been submitted.',
                  }}
                />
              )}

              {/* CREDIT CARD */}
              <PaymentTypeCreditCard
                closeModals={closeModals}
                isOpen={modalState.credit}
                openModal={openModal}
                order={order}
              />

              {/* AFFIRM */}
              {showTypes.affirm && <Affirm setOrderInfo={() => setOrderInfo(PAYMENT_LABELS.AFF, FIELD)} />}

              {/* ROOMS TO GO FINANCING (SYNCHRONY) */}
              {showTypes.synchrony && (
                <PaymentTypeSynchrony
                  closeModals={closeModals}
                  isOpen={modalState.finance}
                  openModal={openModal}
                  order={order}
                />
              )}

              {/* ROOMS TO GO FINANCING (WELLS FARGO) */}
              {showTypes.wells && (
                <PaymentTypeWellsFargo
                  closeModals={closeModals}
                  emailAddress={order.contact.email}
                  isOpen={modalState.wells}
                  openModal={openModal}
                  orderId={order.orderId}
                  paymentInfo={order?.paymentInfo ?? []}
                />
              )}

              {/* PAYPAL */}
              {showTypes.paypal && <PaypalPaymentOption order={order} setOrderInfo={setOrderInfo} />}

              {showTypes.applePay ? (
                /* APPLE PAY */
                <ApplePayPaymentOption
                  hasBottomBorder={!showAll}
                  isDisabled={hasApplePayApplied || order?.amountDue === 0}
                  setOrderInfo={() => setOrderInfo(PAYMENT_LABELS.APP, FIELD)}
                />
              ) : (
                <>
                  {/* Acima */}
                  {showTypes.acima && (
                    <PaymentTypeAcima
                      hasBottomBorder={!showAll}
                      closeModals={closeModals}
                      isOpen={modalState.acima}
                      openModal={openModal}
                      orderId={order.orderId}
                    />
                  )}
                </>
              )}

              {/* KLARNA */}
              {showTypes.klarna && (
                <PaymentTypeKlarna
                  hasBottomBorder={!showAll}
                  closeModals={closeModals}
                  isOpen={modalState.klarna}
                  openModal={openModal}
                  order={order}
                />
              )}

              <Collapse in={showAll || doNotCollapse} timeout="auto">
                {/* Acima */}
                {showTypes.applePay && showTypes.acima && (
                  <PaymentTypeAcima
                    closeModals={closeModals}
                    isOpen={modalState.acima}
                    openModal={openModal}
                    orderId={order.orderId}
                  />
                )}

                {/* BITPAY */}
                {showTypes.bitpay && (
                  <PaymentTypeBitpay
                    closeModals={closeModals}
                    isOpen={modalState.bitpay}
                    openModal={openModal}
                    orderId={order.orderId}
                  />
                )}

                {/* CONCORA (formerly known as GENESIS) */}
                {showTypes.concora && (
                  <PaymentTypeConcora closeModals={closeModals} isOpen={modalState.genesis} openModal={openModal} />
                )}

                {/* GIFT CARD */}
                {showTypes.giftCard && (
                  <PaymentTypeGiftCard
                    closeModals={closeModals}
                    isApplied={hasGiftCardApplied}
                    isOpen={modalState.giftCard}
                    openModal={openModal}
                    order={order}
                  />
                )}
              </Collapse>

              {!doNotCollapse && (
                <ShowAllButton variant="text" onClick={() => setShowAll(current => !current)}>
                  {showAll ? 'View Less Payment Methods' : 'View More Payment Methods'}
                </ShowAllButton>
              )}

              {/*
                // TODO why are these tooltips here? Can this be removed?
              */}
              <ReactToolTip id="creditDisabled" place="top" type="dark" effect="float">
                Must remove financing to use another payment method.
              </ReactToolTip>
              <ReactToolTip id="Affirm" place="top" type="dark" effect="float">
                Can't use Affirm to submit a down payment.
              </ReactToolTip>
              <ReactToolTip place="top" type="dark" effect="float">
                Must remove financing to use another payment method.
              </ReactToolTip>
            </PaymentForm>
          )}

          {/* APPLIED PAYMENTS */}
          {appliedPayments?.length > 0 && (
            <>
              <AppliedPaymentsContainer>
                <AppliedPaymentsHeader>Payments Toward Total</AppliedPaymentsHeader>
                <Grid container spacing={1} sx={{ marginLeft: isMobile && 0, width: isMobile ? '100%' : 'auto' }}>
                  {appliedPayments.map((payment, i) => {
                    const divKey = `pymnt_${i}_${payment.paymentType}`
                    return (
                      <Grid item key={divKey} xs={isMobile && 12} sx={{ padding: isMobile && '5px' }}>
                        <AppliedPaymentCard
                          isMobile={isMobile}
                          handlePaymentRemoval={() => confirmPaymentRemoval(payment)}
                          order={order}
                          payment={payment}
                          showRemove
                        />
                      </Grid>
                    )
                  })}
                </Grid>
                <FinanceMessage paymentInfo={paymentInfo} />
              </AppliedPaymentsContainer>

              <RemovePaymentModal
                cardInfoRef={cardInfoRef}
                closeModals={closeModals}
                order={order}
                removePayment={removePayment}
                setShowRemoveConfirmation={setShowRemoveConfirmation}
                showRemoveConfirmation={showRemoveConfirmation}
              />
            </>
          )}
        </PaymentWrapper>
      )}
    </>
  )
}

PaymentSection.propTypes = {
  cart: object,
  checkoutStepsCompleted: objectOf(bool),
  invalidFields: array,
  isPaymentStep: bool,
  order: object,
}

export default PaymentSection
