import React from 'react'
import { object } from 'prop-types'
import RTGLink from '@shared/link'

const DoorwayDeliveryMessage = () => (
  <>
    <span style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
      Does not include professional assembly or removal of packaging.{' '}
    </span>
    By selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth in the&nbsp;
    <RTGLink
      action="click"
      data={{
        url: 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
        title: 'Rooms To Go Terms of Use',
        action: 'click',
        target: '_blank',
      }}
    >
      <span style={{ fontSize: '0.7rem' }}>
        Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
      </span>
    </RTGLink>
    .
  </>
)

const DeliverySectionAutomatedMessage = ({ order }) => {
  if (order?.calendarType === 'weekly' || order?.defaultDelivery || order?.isPickup || !order?.lineItems) return null

  const isExpress = order?.isExpress || order?.splitDeliveryDates?.some(d => d.expressDelivery)
  const message = `*You will be notified of the ${isExpress ? '' : 'estimated '}delivery window ${
    isExpress ? '' : '2 days '
  }prior to delivery. `

  return (
    <p style={{ fontSize: '12px', marginLeft: '12px' }}>
      {message}
      {order?.doorwayDelivery && <DoorwayDeliveryMessage />}
    </p>
  )
}

DeliverySectionAutomatedMessage.propTypes = {
  order: object,
}

export default DeliverySectionAutomatedMessage
