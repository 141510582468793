import React from 'react'
import { any, arrayOf, objectOf, number, bool, string, array } from 'prop-types'
import { Stack, Typography } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'
import { getProductDisplayImage } from '@helpers/product'
import { getDateFull } from '@helpers/date'
import { useTest } from '@hooks/useTest'
import { PriceAtom } from '@rtgdev/design-system'
import { StyledDeliveryCount, StyledDeliveryDate, StyleDeliveryType, StyledGrid } from './styles-mui'
import MiniProductTile from './mini-product-tile'
import CalendarPopup from './delivery-section-calendar-popup'
import DistributionCenterPopup from './distribution-center-popup'
import EstimatedDeliveryMessage from './estimated-delivery-message'

const SingleDelivery = ({
  cartItems,
  defaultDeliveryInterval,
  deliveryCount,
  deliveryOptionString,
  includesExpressFee,
  isDeliveryEditable = true, // true when Delivery step is active, false for condensed view
  isMobile,
  order,
  rtgDeliveryItems,
}) => {
  const { generateTestId } = useTest()
  const storeCartItems = order?.lineItems?.filter(x => x.isStoreSku) ?? []
  const deliveryItems = [...cartItems, ...storeCartItems]
    .filter(i => rtgDeliveryItems.map(itm => itm.sku).includes(i.sku))
    .map(delItem => ({
      activeAddons: delItem?.activeAddons ?? null,
      href: delItem?.product?.route ?? '',
      image: delItem?.product
        ? getProductDisplayImage(delItem.product)
        : order?.storeCart?.lineItems?.find(s => s.sku === delItem.sku)?.imageUrl ?? null,
      isConfigurable: delItem?.product?.isConfigurable ?? false,
      isStoreSku: delItem?.isStoreSku ?? false,
      quantity: delItem?.quantity,
      requiredAddon: delItem?.product?.required ?? false,
      selections: delItem?.selections,
      sku: delItem?.sku,
      title: delItem?.product?.title ?? delItem?.title,
    }))

  const { calendarType, defaultDelivery: isDefault, deliveryDate, isExpress, isPickup } = order
  const isEstimatedDelivery = calendarType === 'weekly'
  const deliveryDateLongText = isDefault ? defaultDeliveryInterval : getDateFull(deliveryDate, true)

  return (
    <StyledGrid item xs={12} md={deliveryCount > 1 ? 6 : 8}>
      {deliveryCount > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledDeliveryCount>DELIVERY 1 OF {deliveryCount}</StyledDeliveryCount>
          {isDeliveryEditable && isExpress && !isPickup && (
            <Stack direction="row" alignItems="center">
              <StyledDeliveryCount>-</StyledDeliveryCount>
              <BoltIcon htmlColor="#003566" />
              <StyledDeliveryCount>EXPRESS DELIVERY</StyledDeliveryCount>
            </Stack>
          )}
          {isDefault && <StyledDeliveryCount>- ESTIMATED DATES</StyledDeliveryCount>}
        </Stack>
      )}
      {isPickup ? (
        <Stack>
          <Stack direction="row" spacing={0.5}>
            <span>Pickup from</span>
            <DistributionCenterPopup />
            <span>on:</span>
          </Stack>
          <StyledDeliveryDate data-testid={generateTestId('Checkout', 'DeliveryDate')}>
            {getDateFull(deliveryDate, true)}
          </StyledDeliveryDate>
        </Stack>
      ) : (
        <StyledDeliveryDate data-testid={generateTestId('Checkout', 'DeliveryDate')}>
          {isEstimatedDelivery && <span style={{ fontWeight: 400 }}>Est. </span>}
          {deliveryDateLongText}
        </StyledDeliveryDate>
      )}
      {!isDeliveryEditable && deliveryOptionString && (
        <Stack direction="row" alignItems="center">
          {isExpress && <BoltIcon htmlColor="#003566" />}
          <StyleDeliveryType>{deliveryOptionString}</StyleDeliveryType>
        </Stack>
      )}
      {!isMobile && isDeliveryEditable && !isDefault && (
        <CalendarPopup
          initialDate={deliveryDate}
          order={order}
          testId="select-delivery-date-button"
          text={isPickup ? 'Change Pickup Date' : 'Change Delivery Date'}
        />
      )}
      {isDefault && (
        <Typography sx={{ maxWidth: 460 }}>
          We will contact you to confirm actual delivery date and charge once your order is fully processed.
        </Typography>
      )}
      {isDeliveryEditable && includesExpressFee && !isMobile && (
        <Stack direction="row" spacing={0.5}>
          <PriceAtom price={order.premiumExpressFee} />
          <Typography sx={{ maxWidth: 450 }}>Express delivery charge included in total</Typography>
        </Stack>
      )}

      <Stack>
        {deliveryItems.map((item, index) => {
          const key = `delivery_item_${item.sku}_${index}`
          return (
            <Stack key={key} mt={2}>
              <MiniProductTile
                href={item.href}
                image={item.image}
                isConfigurable={item.isConfigurable}
                isStoreSku={item.isStoreSku}
                quantity={item.quantity}
                requiredAddon={item.requiredAddon}
                selections={item.selections}
                sku={item.sku}
                title={item.title}
              />
              {item.activeAddons &&
                item.activeAddons.map(addonItem => (
                  <MiniProductTile
                    key={addonItem?.sku}
                    href={addonItem?.route}
                    image={addonItem?.image || addonItem?.primary_image}
                    isStoreSku={item.isStoreSku}
                    quantity={addonItem?.quantity && addonItem?.quantity * item.quantity}
                    requiredAddon={addonItem?.required}
                    sku={addonItem?.sku}
                    title={addonItem?.title}
                  />
                ))}
            </Stack>
          )
        })}
        {isEstimatedDelivery && <EstimatedDeliveryMessage deliveryDate={deliveryDateLongText} />}
      </Stack>
    </StyledGrid>
  )
}

SingleDelivery.propTypes = {
  cartItems: array,
  defaultDeliveryInterval: string,
  deliveryCount: number,
  deliveryOptionString: string,
  includesExpressFee: bool,
  isDeliveryEditable: bool,
  isMobile: bool,
  order: objectOf(any),
  rtgDeliveryItems: arrayOf(any),
}

export default SingleDelivery
