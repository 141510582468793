import React, { useRef } from 'react'
import { func, bool } from 'prop-types'
import { Button, Grid, Stack, styled } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ApplePay from './apple-pay'

const Label = styled('span')`
  display: inline-block;
  text-transform: capitalize;
  color: #333;
  font-weight: 400;
`

const Container = styled(Button)`
  border-top: 1px solid #dedede;
  border-radius: 0;
  padding: 10px 10px 10px 7px;
  justify-content: flex-start;
  min-height: 75px;
`

const ApplePayPaymentOption = ({ hasBottomBorder, isApplied, isDisabled, setOrderInfo }) => {
  const applePayRef = useRef(null)

  return (
    <Container
      color="primary"
      disabled={isDisabled}
      fullWidth
      variant="text"
      onClick={() => applePayRef?.current?.handleClick?.()}
      style={hasBottomBorder ? { borderBottom: '1px solid #dedede' } : null}
    >
      <Stack direction="row" sx={{ width: '100%' }} alignItems="center">
        <Grid item sx={{ width: '175px', height: '55px', marginRight: '20px', zIndex: 1, alignContent: 'center' }}>
          <ApplePay ref={applePayRef} type="Plain" isCheckout setOrderInfo={setOrderInfo} style={{ height: '55px' }} />
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ flexGrow: 1 }}>
          <Label>Apple Pay</Label>
          {isApplied && <CheckCircleOutlineIcon sx={{ fontSize: '24px !important' }} color="success" />}
        </Stack>
      </Stack>
    </Container>
  )
}

ApplePayPaymentOption.propTypes = {
  hasBottomBorder: bool,
  isApplied: bool,
  isDisabled: bool,
  setOrderInfo: func,
}

export default ApplePayPaymentOption
